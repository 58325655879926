<template>
  <b-modal
    v-model="modalShow"
    centered
    :title="selectedMessage.title"
    no-close-on-backdrop
  >
    <div v-if="selectedMessage">
      <h6>
        {{ selectedMessage.subtitle }}
      </h6>
      <p style="word-wrap: break-word;">
        {{ selectedMessage.message }}
      </p>
    </div>
    <template #modal-footer>
      <b-button
        size="sm"
        @click="modalShow=false"
      >
        {{ $t('close') }}
      </b-button>
      <b-button
        v-if="selectedMessage.event"
        variant="primary"
        size="sm"
        @click="handleOk()"
      >
        {{ $t('countinue') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
  },

  computed: {
    modalShow: {
      get() {
        return this.$store.state.Messaging.modalShow
      },
      set(newValue) {
        this.$store.commit('Messaging/TOGGLE_MODAL_SHOW', newValue)
      },
    },
    selectedMessage() {
      return this.$store.state.Messaging.selectedMessage
    },
  },

  methods: {
    handleOk() {
      const { event } = this.selectedMessage
      let route = ''
      if (event) {
        const matchTicket = event.match(/^ticket-(\d+)$/)
        if (matchTicket) {
          const ticketNumber = matchTicket[1]
          route = `tickets/${ticketNumber}`
        } else {
          switch (event) {
            case 'document_general':
              route = { name: 'document-general' }
              break
            case 'document_bank':
              route = { name: 'document-bank' }
              break
            case 'platform_forex_deposit_wire':
              route = { name: 'forexModule-bank-deposit' }
              break
            case 'platform_forex_deposit_crypto':
              route = { name: 'forexModule-crypto-deposit' }
              break
            case 'platform_forex_withdrawal_wire':
              route = { name: 'forexModule-bank-withdrawal' }
              break
            case 'platform_forex_withdrawal_crypto':
              route = { name: 'forexModule-crypto-withdrawal' }
              break
            default:
              route = { name: 'dashboard' }
          }
        }

        // check we not be in the expected route
        const expectedRoute = route.name
        const currentRoute = this.$route.name
        if (expectedRoute !== currentRoute) {
          this.$router.push(route)
          this.modalShow = false
          // return route
        } if (expectedRoute === route) {
          this.$router.go(0)
          this.modalShow = false
          // return { name: currentRoute }
        }
      }
      // return route
    },
  },
}
</script>
